/**
 * This list was extracted from the "ngerman" wordlist from
 * https://github.com/davidblitz/german-wordlist using the RegExp
 * (?<![äöüÄÖÜß\w])([äöüÄÖÜß\w]{5})(?![äöüÄÖÜß\w])
 * https://regex101.com/r/5B44sP/1
 *
 * Extra words added:
 * - anime
 * - alien
 * - erpel
 * - herzt
 * - kacke
 * - kebab
 * - kebap
 * - zarge
 * - zumba
 */

export const VALID_GUESSES = [
  'ascii',
  'aales',
  'aases',
  'abart',
  'abbau',
  'abels',
  'abend',
  'abgas',
  'abort',
  'abruf',
  'absud',
  'abtei',
  'abtes',
  'abweg',
  'abzug',
  'acers',
  'achim',
  'achse',
  'acker',
  'acryl',
  'adams',
  'adels',
  'adern',
  'adler',
  'adobe',
  'adolf',
  'adria',
  'affen',
  'after',
  'agave',
  'agent',
  'agfas',
  'agios',
  'ahlen',
  'ahorn',
  'akaba',
  'akkus',
  'akten',
  'aktes',
  'aktie',
  'aktor',
  'alarm',
  'albas',
  'alben',
  'album',
  'aldis',
  'alfas',
  'algen',
  'alibi',
  'alice',
  'allah',
  'allee',
  'almen',
  'alpen',
  'altar',
  'altöl',
  'ammen',
  'amors',
  'ampel',
  'amrum',
  'amsel',
  'amtes',
  'anbau',
  'anden',
  'andys',
  'angel',
  'anime',
  'anion',
  'anita',
  'anjas',
  'anker',
  'ankes',
  'anmut',
  'annas',
  'annes',
  'annie',
  'anode',
  'anruf',
  'antje',
  'anton',
  'anwar',
  'anzug',
  'apfel',
  'apoll',
  'apple',
  'april',
  'apsis',
  'arals',
  'arche',
  'areal',
  'arena',
  'argon',
  'argus',
  'arien',
  'arier',
  'armee',
  'armut',
  'arndt',
  'arnos',
  'aroma',
  'arosa',
  'array',
  'arsch',
  'arsen',
  'artus',
  'asche',
  'asiat',
  'asien',
  'asket',
  'aspik',
  'assel',
  'assen',
  'asses',
  'aster',
  'astes',
  'asyls',
  'atari',
  'atems',
  'athen',
  'atlas',
  'atoll',
  'atome',
  'atoms',
  'audio',
  'audis',
  'augen',
  'auges',
  'aurel',
  'autor',
  'autos',
  'axels',
  'axiom',
  'bghst',
  'babel',
  'babys',
  'bachs',
  'bades',
  'bafög',
  'bahre',
  'baien',
  'baken',
  'balls',
  'balte',
  'bambi',
  'bande',
  'bands',
  'banjo',
  'bantu',
  'barak',
  'barde',
  'barke',
  'baron',
  'basar',
  'basel',
  'basen',
  'basic',
  'basis',
  'baske',
  'batik',
  'bator',
  'bauch',
  'bauer',
  'baues',
  'baums',
  'bayer',
  'beate',
  'becks',
  'beere',
  'beete',
  'beets',
  'behrs',
  'beile',
  'beils',
  'beine',
  'beins',
  'belag',
  'beleg',
  'bergs',
  'bernd',
  'berns',
  'berta',
  'berts',
  'beruf',
  'besen',
  'betel',
  'betts',
  'betty',
  'beule',
  'bezug',
  'bibel',
  'biber',
  'biene',
  'biere',
  'biers',
  'biest',
  'biker',
  'bilds',
  'bills',
  'billy',
  'binom',
  'binse',
  'birke',
  'birne',
  'bisse',
  'biwak',
  'björn',
  'blair',
  'blatt',
  'blech',
  'bleie',
  'bleis',
  'blitz',
  'blogs',
  'blues',
  'bluff',
  'blume',
  'bluse',
  'bluts',
  'blöße',
  'blüte',
  'bocks',
  'boden',
  'bohle',
  'bohne',
  'bohrs',
  'bojen',
  'bombe',
  'bongo',
  'bonns',
  'bonus',
  'bonze',
  'booms',
  'boots',
  'borde',
  'bords',
  'boris',
  'borke',
  'borte',
  'bosch',
  'boson',
  'bosse',
  'botin',
  'boucl',
  'bourr',
  'bowle',
  'boxer',
  'bozen',
  'brand',
  'breie',
  'breis',
  'brest',
  'brett',
  'brief',
  'brise',
  'brite',
  'brote',
  'brots',
  'bruch',
  'bruno',
  'brust',
  'bryan',
  'buben',
  'buchs',
  'buden',
  'buges',
  'bulle',
  'bunde',
  'bunds',
  'burda',
  'burma',
  'busch',
  'busen',
  'bushs',
  'busse',
  'butan',
  'butze',
  'bußen',
  'bytes',
  'bäche',
  'bäder',
  'bälde',
  'bälle',
  'bände',
  'bänke',
  'bären',
  'bärin',
  'bärte',
  'bässe',
  'bäume',
  'böcke',
  'böden',
  'bögen',
  'böhme',
  'börde',
  'börse',
  'bügel',
  'bühne',
  'bünde',
  'büros',
  'büste',
  'büßer',
  'cache',
  'cadiz',
  'camps',
  'canon',
  'carlo',
  'carol',
  'cathy',
  'cebit',
  'celle',
  'celli',
  'cello',
  'cents',
  'chaos',
  'chaot',
  'chart',
  'chats',
  'check',
  'chefs',
  'chile',
  'china',
  'chips',
  'chlor',
  'choke',
  'chors',
  'chose',
  'chrom',
  'churs',
  'teaus',
  'chöre',
  'cisco',
  'citys',
  'civil',
  'clips',
  'clone',
  'clous',
  'clown',
  'clubs',
  'cluny',
  'coats',
  'cobol',
  'codec',
  'codes',
  'codex',
  'colas',
  'colts',
  'comic',
  'couch',
  'coupe',
  'coups',
  'cover',
  'crash',
  'crews',
  'curie',
  'curry',
  'cäsar',
  'dachs',
  'dakar',
  'dalai',
  'dalis',
  'damen',
  'damms',
  'dampf',
  'dandy',
  'danks',
  'dante',
  'darms',
  'datei',
  'daten',
  'datex',
  'dativ',
  'datum',
  'dauer',
  'daune',
  'david',
  'davis',
  'davos',
  'deals',
  'debüt',
  'decks',
  'degen',
  'deich',
  'dekan',
  'dekor',
  'delhi',
  'delle',
  'dells',
  'demos',
  'demut',
  'depot',
  'depps',
  'devon',
  'dhabi',
  'diana',
  'diebe',
  'diebs',
  'diele',
  'dildo',
  'dills',
  'dinar',
  'dinge',
  'dingo',
  'dings',
  'diode',
  'dipol',
  'dirks',
  'dirne',
  'disco',
  'divas',
  'diwan',
  'docht',
  'docks',
  'dogen',
  'dogge',
  'dogma',
  'dohle',
  'dokus',
  'dolch',
  'dolly',
  'domen',
  'domes',
  'donau',
  'doras',
  'dorfe',
  'dorfs',
  'doris',
  'dorne',
  'dorns',
  'dosen',
  'dosis',
  'dover',
  'draht',
  'drama',
  'dreck',
  'dress',
  'drift',
  'drink',
  'droge',
  'drüse',
  'dubai',
  'duden',
  'duell',
  'duett',
  'dufts',
  'dumas',
  'dummy',
  'dungs',
  'dunst',
  'durst',
  'dusel',
  'dutte',
  'dutts',
  'dämon',
  'dänen',
  'dänin',
  'därme',
  'döner',
  'dübel',
  'düfte',
  'dünen',
  'dürer',
  'düsen',
  'eprom',
  'ebern',
  'ebers',
  'ebert',
  'ebola',
  'echos',
  'edeka',
  'edens',
  'eders',
  'edgar',
  'edith',
  'edukt',
  'efeus',
  'egeln',
  'egels',
  'eggen',
  'egons',
  'eiben',
  'eiden',
  'eides',
  'eiern',
  'eifel',
  'eifer',
  'eimer',
  'eisen',
  'eises',
  'eiter',
  'ekels',
  'eklat',
  'ekzem',
  'elans',
  'elbas',
  'elche',
  'elchs',
  'elfen',
  'elias',
  'elite',
  'eliza',
  'elkes',
  'ellen',
  'emden',
  'emils',
  'emmas',
  'endes',
  'engel',
  'enkel',
  'enten',
  'enzym',
  'epson',
  'erbes',
  'erbin',
  'erbse',
  'erdöl',
  'erich',
  'erika',
  'erker',
  'erlen',
  'erlös',
  'erpel',
  'erwin',
  'erzen',
  'erzes',
  'esche',
  'eseln',
  'esels',
  'essay',
  'esser',
  'essig',
  'essos',
  'etage',
  'etats',
  'ethik',
  'ethos',
  'etons',
  'etüde',
  'eulen',
  'euler',
  'eupen',
  'euros',
  'euter',
  'excel',
  'exile',
  'exils',
  'expos',
  'fabel',
  'fachs',
  'fahne',
  'fakts',
  'falke',
  'fango',
  'fangs',
  'farbe',
  'farce',
  'farne',
  'farns',
  'fasan',
  'faser',
  'fatum',
  'fauna',
  'faune',
  'fauns',
  'faust',
  'faxes',
  'fazit',
  'feder',
  'fehde',
  'feier',
  'felde',
  'felds',
  'felge',
  'felix',
  'felle',
  'fells',
  'fermi',
  'ferse',
  'fests',
  'fetts',
  'feuer',
  'fezen',
  'fezes',
  'fiats',
  'fibel',
  'ficks',
  'figur',
  'filet',
  'films',
  'finne',
  'finte',
  'firma',
  'first',
  'fisch',
  'fjord',
  'flair',
  'flash',
  'flaum',
  'fleck',
  'fleiß',
  'flirt',
  'flohs',
  'flops',
  'flora',
  'fluch',
  'fluge',
  'fluid',
  'fluor',
  'flure',
  'flurs',
  'fluss',
  'flyer',
  'flöze',
  'flüge',
  'fokus',
  'folie',
  'fonds',
  'fords',
  'foren',
  'forsa',
  'forst',
  'forum',
  'fotos',
  'fotze',
  'fouls',
  'foyer',
  'frack',
  'franc',
  'frank',
  'fraße',
  'freak',
  'freud',
  'frist',
  'fritz',
  'front',
  'frost',
  'frust',
  'fuchs',
  'fuder',
  'fugen',
  'fuhre',
  'fujis',
  'fulda',
  'funde',
  'funds',
  'funks',
  'furie',
  'fusel',
  'futur',
  'fußes',
  'fäden',
  'fähre',
  'fänge',
  'fäule',
  'föhns',
  'föhre',
  'föten',
  'fötus',
  'fürst',
  'fürth',
  'fürze',
  'füßen',
  'gbyte',
  'gabel',
  'gabun',
  'gagen',
  'galle',
  'gange',
  'gangs',
  'garbe',
  'garbo',
  'garde',
  'garne',
  'garns',
  'gasen',
  'gases',
  'gasse',
  'gassi',
  'gasöl',
  'gatte',
  'gaudi',
  'gauls',
  'gazen',
  'geber',
  'gebet',
  'gecko',
  'gefäß',
  'gehör',
  'geier',
  'geist',
  'gelde',
  'gelds',
  'gelee',
  'gelen',
  'gemüt',
  'genen',
  'genfs',
  'genie',
  'genom',
  'genre',
  'genua',
  'genus',
  'georg',
  'gerda',
  'gerds',
  'gerte',
  'geste',
  'gesäß',
  'getto',
  'getue',
  'geäst',
  'ghana',
  'gicht',
  'gifts',
  'gilde',
  'ginas',
  'ginko',
  'giros',
  'gizeh',
  'glanz',
  'gleis',
  'glied',
  'glück',
  'gnade',
  'golda',
  'golds',
  'golfs',
  'gongs',
  'gorki',
  'gosse',
  'goten',
  'gotha',
  'gotik',
  'gotin',
  'gouda',
  'goyas',
  'grabs',
  'grace',
  'grade',
  'grads',
  'grals',
  'gramm',
  'graph',
  'grate',
  'grats',
  'graus',
  'greis',
  'grete',
  'grieß',
  'grimm',
  'grips',
  'grogs',
  'groll',
  'grube',
  'gruft',
  'grund',
  'gräte',
  'größe',
  'guido',
  'gulag',
  'gully',
  'gummi',
  'gunst',
  'gurke',
  'gurte',
  'gurts',
  'gurus',
  'gusto',
  'gysis',
  'gämse',
  'gänge',
  'gänse',
  'gänze',
  'gäste',
  'gäule',
  'gödel',
  'gören',
  'götze',
  'güsse',
  'güter',
  'haags',
  'haare',
  'haars',
  'hades',
  'hafen',
  'hafer',
  'hagel',
  'hagen',
  'hahns',
  'haien',
  'haies',
  'haifa',
  'haine',
  'hains',
  'haiti',
  'halde',
  'halls',
  'halme',
  'halms',
  'halts',
  'hamas',
  'hamed',
  'hanau',
  'handy',
  'hanfs',
  'hangs',
  'hanne',
  'hanoi',
  'hanse',
  'harem',
  'harfe',
  'harns',
  'harro',
  'harry',
  'harze',
  'hasen',
  'haube',
  'hauer',
  'hauff',
  'haupt',
  'havel',
  'haydn',
  'hebel',
  'heber',
  'hecht',
  'hecks',
  'hedda',
  'hedys',
  'heere',
  'heers',
  'hefen',
  'hefts',
  'hegel',
  'heide',
  'heidi',
  'heike',
  'heiko',
  'heime',
  'heims',
  'heine',
  'heino',
  'heinz',
  'helds',
  'helga',
  'helme',
  'helms',
  'helot',
  'hemds',
  'henne',
  'henry',
  'heras',
  'herde',
  'herds',
  'herrn',
  'herta',
  'hertz',
  'herzt',
  'hesse',
  'heuss',
  'hexer',
  'hiebe',
  'hilde',
  'hilfe',
  'hindu',
  'hirne',
  'hirns',
  'hirse',
  'hirte',
  'hitze',
  'hiwis',
  'hoare',
  'hobby',
  'hochs',
  'hoden',
  'hofes',
  'hohns',
  'holme',
  'holms',
  'homer',
  'honda',
  'honig',
  'horde',
  'horns',
  'horny',
  'horse',
  'horst',
  'horts',
  'hosen',
  'hotel',
  'huber',
  'hubes',
  'hufen',
  'hufes',
  'hugos',
  'huhns',
  'humor',
  'humus',
  'hunde',
  'hunds',
  'hunne',
  'husar',
  'husum',
  'hutes',
  'hydra',
  'hymne',
  'hyäne',
  'häfen',
  'hähne',
  'hälse',
  'hände',
  'häute',
  'höfen',
  'höhen',
  'höhle',
  'hölle',
  'hörer',
  'hüfte',
  'hügel',
  'hülse',
  'hünen',
  'hürde',
  'hüter',
  'hütte',
  'icons',
  'ideen',
  'idiot',
  'idole',
  'idols',
  'idyll',
  'igels',
  'ikone',
  'iltis',
  'image',
  'imame',
  'imams',
  'imker',
  'inbus',
  'inder',
  'index',
  'indio',
  'indiz',
  'indus',
  'infos',
  'ingos',
  'inkas',
  'insel',
  'intel',
  'inuit',
  'ionen',
  'iraks',
  'irans',
  'isaac',
  'isaak',
  'islam',
  'ivans',
  'jacht',
  'jacke',
  'jacks',
  'jacob',
  'jaffa',
  'jahns',
  'jahre',
  'jahrs',
  'jahwe',
  'jakob',
  'jalta',
  'james',
  'japan',
  'javas',
  'jeans',
  'jeeps',
  'jemen',
  'jenas',
  'jesus',
  'joche',
  'jochs',
  'jogas',
  'johns',
  'joker',
  'jolle',
  'jones',
  'josef',
  'joule',
  'jubel',
  'judas',
  'juden',
  'judos',
  'judäa',
  'juist',
  'julia',
  'julis',
  'jumbo',
  'junis',
  'juras',
  'juror',
  'jurys',
  'jutta',
  'juwel',
  'juxen',
  'juxes',
  'jäger',
  'jörgs',
  'jüdin',
  'kbaud',
  'kbyte',
  'kpdsu',
  'kaaba',
  'kabel',
  'kabul',
  'kacke',
  'kader',
  'kadis',
  'kaffs',
  'kafka',
  'kahns',
  'kairo',
  'kajak',
  'kakao',
  'kalbs',
  'kalif',
  'kalis',
  'kalks',
  'kamel',
  'kamin',
  'kamms',
  'kampf',
  'kanal',
  'kanne',
  'kanon',
  'kants',
  'kanus',
  'karat',
  'karin',
  'karla',
  'karls',
  'karos',
  'karte',
  'kasko',
  'kasse',
  'kasus',
  'kater',
  'katia',
  'katze',
  'kaufs',
  'kebab',
  'kebap',
  'kehle',
  'keils',
  'keims',
  'kekse',
  'kelch',
  'kelle',
  'kenia',
  'kerle',
  'kerls',
  'kerne',
  'kerns',
  'kerze',
  'keule',
  'khans',
  'khmer',
  'kiele',
  'kiels',
  'kiepe',
  'kiews',
  'kilos',
  'kimme',
  'kinds',
  'kinne',
  'kinns',
  'kinos',
  'kiosk',
  'kioto',
  'kirch',
  'kiste',
  'kitas',
  'kitts',
  'klaus',
  'klees',
  'kleid',
  'kleie',
  'kleve',
  'klima',
  'klops',
  'klotz',
  'klubs',
  'kluft',
  'klöße',
  'knabe',
  'knast',
  'knauf',
  'knaur',
  'knies',
  'knopf',
  'kobra',
  'kochs',
  'kodak',
  'kodex',
  'kohle',
  'kohls',
  'kojen',
  'kokon',
  'kolik',
  'komas',
  'kombi',
  'komet',
  'komik',
  'komma',
  'kongo',
  'konto',
  'kopfe',
  'kopfs',
  'kopie',
  'koran',
  'korbs',
  'korea',
  'korns',
  'korps',
  'korse',
  'korso',
  'kosak',
  'krach',
  'krake',
  'krams',
  'krans',
  'kranz',
  'kraut',
  'krebs',
  'kreis',
  'kreml',
  'krepp',
  'kreta',
  'kreuz',
  'krimi',
  'kripo',
  'krise',
  'krone',
  'kropf',
  'krugs',
  'krume',
  'kräne',
  'kröte',
  'krüge',
  'kubas',
  'kuben',
  'kubus',
  'kufen',
  'kugel',
  'kuhle',
  'kulis',
  'kulte',
  'kults',
  'kunde',
  'kunst',
  'kupon',
  'kuppe',
  'kurde',
  'kurie',
  'kurse',
  'kurts',
  'kutte',
  'käfer',
  'käfig',
  'kähne',
  'kälte',
  'käses',
  'käufe',
  'köche',
  'köder',
  'kölns',
  'könig',
  'körbe',
  'köter',
  'kübel',
  'küche',
  'kühen',
  'küken',
  'küste',
  'latex',
  'labor',
  'lachs',
  'lacke',
  'lacks',
  'lader',
  'ladys',
  'lager',
  'lagos',
  'laibe',
  'laibs',
  'laien',
  'lakai',
  'laken',
  'lamas',
  'lamee',
  'lamms',
  'lampe',
  'lands',
  'lanka',
  'lanze',
  'larve',
  'lasso',
  'latte',
  'laube',
  'laubs',
  'lauch',
  'laufs',
  'laune',
  'lauts',
  'lears',
  'leber',
  'lechs',
  'lecks',
  'leder',
  'legat',
  'lehms',
  'leibe',
  'leibs',
  'leica',
  'leids',
  'leier',
  'leine',
  'lemma',
  'lende',
  'lenin',
  'lenze',
  'lepra',
  'lesbe',
  'leser',
  'lette',
  'letzt',
  'leute',
  'level',
  'liane',
  'lider',
  'lides',
  'lidos',
  'lieds',
  'lifts',
  'ligen',
  'likör',
  'lilie',
  'lille',
  'limes',
  'limit',
  'limos',
  'linie',
  'linse',
  'linus',
  'linux',
  'lippe',
  'lisas',
  'liszt',
  'liter',
  'litze',
  'lloyd',
  'lobby',
  'lobes',
  'lochs',
  'logik',
  'login',
  'logis',
  'logos',
  'lohns',
  'loire',
  'lords',
  'loren',
  'lotes',
  'lotos',
  'lotte',
  'lotto',
  'louis',
  'luchs',
  'luder',
  'luken',
  'lumen',
  'lunge',
  'lunte',
  'lupen',
  'luxus',
  'lyrik',
  'läden',
  'länge',
  'lärms',
  'läufe',
  'läuse',
  'lösse',
  'löwen',
  'löwin',
  'lößen',
  'lößes',
  'lücke',
  'lüste',
  'mbyte',
  'msdos',
  'maare',
  'macho',
  'macke',
  'maden',
  'mafia',
  'magen',
  'magie',
  'magma',
  'mahls',
  'mails',
  'mainz',
  'major',
  'makel',
  'makro',
  'maler',
  'malmö',
  'malos',
  'malta',
  'malus',
  'malve',
  'mamas',
  'manie',
  'manko',
  'manna',
  'manne',
  'manns',
  'maori',
  'mappe',
  'marcs',
  'marge',
  'maria',
  'marie',
  'marke',
  'markt',
  'marne',
  'marys',
  'maske',
  'masse',
  'mathe',
  'mauer',
  'mauls',
  'mayas',
  'mazda',
  'maßen',
  'maßes',
  'media',
  'meere',
  'meers',
  'mehle',
  'mehls',
  'meier',
  'meile',
  'meise',
  'mekka',
  'memel',
  'mensa',
  'menüs',
  'meran',
  'merck',
  'meter',
  'metro',
  'meute',
  'meyer',
  'miami',
  'micks',
  'midas',
  'miefs',
  'miene',
  'mieze',
  'milan',
  'milbe',
  'milch',
  'miliz',
  'mimen',
  'mimik',
  'minen',
  'mings',
  'minis',
  'minsk',
  'mists',
  'mitte',
  'mixer',
  'modem',
  'moden',
  'modul',
  'modus',
  'mofas',
  'mogul',
  'mohns',
  'mokka',
  'molen',
  'momos',
  'monat',
  'monde',
  'monte',
  'moore',
  'moors',
  'moose',
  'moped',
  'mopps',
  'moral',
  'mords',
  'moron',
  'mosel',
  'moses',
  'mosts',
  'motel',
  'motiv',
  'motor',
  'motte',
  'motto',
  'muffe',
  'mulde',
  'multi',
  'mumie',
  'mumms',
  'mummy',
  'mumps',
  'murks',
  'musen',
  'musik',
  'mutes',
  'mutti',
  'mysql',
  'mädel',
  'mäher',
  'mähne',
  'mäuse',
  'mäzen',
  'möbel',
  'möhre',
  'mönch',
  'möpse',
  'möwen',
  'mücke',
  'mühle',
  'mülls',
  'müsli',
  'mütze',
  'nsdap',
  'nabel',
  'naben',
  'nacht',
  'nadel',
  'nagel',
  'nager',
  'namen',
  'namur',
  'napfs',
  'napol',
  'narbe',
  'nasen',
  'natur',
  'nazis',
  'nebel',
  'neffe',
  'neger',
  'negev',
  'negro',
  'nehru',
  'neids',
  'neiße',
  'nelke',
  'neons',
  'nepal',
  'nepps',
  'neros',
  'nervs',
  'nerze',
  'nestl',
  'nests',
  'netze',
  'neuss',
  'niere',
  'niger',
  'nikon',
  'ninas',
  'nixen',
  'nizza',
  'noahs',
  'nokia',
  'nomen',
  'nonne',
  'notar',
  'noten',
  'notiz',
  'novum',
  'nudel',
  'nugat',
  'nuten',
  'nutte',
  'nylon',
  'nägel',
  'näpfe',
  'nässe',
  'nöten',
  'nüsse',
  'oasen',
  'obama',
  'obern',
  'obers',
  'obhut',
  'oboen',
  'obsts',
  'ochse',
  'odems',
  'odium',
  'ofens',
  'oheim',
  'ohios',
  'ohren',
  'ohres',
  'oktan',
  'oktav',
  'olafs',
  'olegs',
  'olgas',
  'olive',
  'olymp',
  'onkel',
  'opels',
  'opern',
  'opium',
  'optik',
  'orbit',
  'orden',
  'order',
  'organ',
  'orgel',
  'orgie',
  'orion',
  'orkan',
  'ortes',
  'oscar',
  'oskar',
  'oslos',
  'osram',
  'osten',
  'otmar',
  'otter',
  'ottos',
  'oxide',
  'oxids',
  'oxyde',
  'oxyds',
  'ozean',
  'ozons',
  'paars',
  'pablo',
  'pacht',
  'paket',
  'pakte',
  'pakts',
  'palme',
  'panik',
  'panne',
  'papas',
  'papst',
  'paris',
  'parks',
  'parts',
  'party',
  'pasta',
  'paste',
  'patch',
  'paten',
  'pater',
  'patin',
  'paula',
  'pauls',
  'pavia',
  'peaks',
  'pechs',
  'pedal',
  'pedro',
  'pegel',
  'peggy',
  'pelze',
  'penis',
  'pepsi',
  'perus',
  'peter',
  'petra',
  'pfade',
  'pfads',
  'pfahl',
  'pfalz',
  'pfand',
  'pfaue',
  'pfaus',
  'pfeil',
  'pferd',
  'pflug',
  'pfote',
  'pfuhl',
  'pfund',
  'phase',
  'photo',
  'piezo',
  'pille',
  'pilot',
  'pilze',
  'pinie',
  'pinne',
  'pippi',
  'pirat',
  'pisas',
  'piste',
  'pixel',
  'pizza',
  'plans',
  'plato',
  'plots',
  'pluto',
  'pläne',
  'pneus',
  'pokal',
  'poker',
  'polen',
  'polin',
  'polis',
  'polle',
  'polyp',
  'pomps',
  'ponys',
  'pools',
  'popen',
  'popos',
  'poren',
  'porno',
  'porti',
  'porto',
  'ports',
  'posen',
  'posse',
  'potis',
  'power',
  'prado',
  'prags',
  'prime',
  'prinz',
  'profi',
  'prosa',
  'proxy',
  'prunk',
  'psalm',
  'pudel',
  'puder',
  'puffs',
  'pulle',
  'pulli',
  'pulte',
  'pults',
  'pumas',
  'pumps',
  'punks',
  'punkt',
  'puppe',
  'puten',
  'puter',
  'putin',
  'pylon',
  'pässe',
  'pöbel',
  'püree',
  'pütts',
  'qualm',
  'quant',
  'quark',
  'quart',
  'quarz',
  'queen',
  'quere',
  'quint',
  'quirl',
  'quota',
  'quote',
  'rabat',
  'raben',
  'rache',
  'radar',
  'radau',
  'rades',
  'radio',
  'radon',
  'rahms',
  'raine',
  'rains',
  'ralfs',
  'ralph',
  'rampe',
  'rande',
  'rands',
  'rangs',
  'rappe',
  'rasse',
  'rates',
  'ratte',
  'raubs',
  'rauch',
  'raudi',
  'raume',
  'raums',
  'raupe',
  'realo',
  'reben',
  'regal',
  'regel',
  'regie',
  'rehen',
  'reifs',
  'reims',
  'rente',
  'reset',
  'reste',
  'rests',
  'revue',
  'rhein',
  'rhone',
  'ricke',
  'riege',
  'riese',
  'riffe',
  'riffs',
  'rigas',
  'rille',
  'rinde',
  'rinds',
  'rippe',
  'risse',
  'riten',
  'ritts',
  'roben',
  'robin',
  'rocks',
  'roger',
  'rohre',
  'rohrs',
  'rohöl',
  'rolex',
  'rolfs',
  'rolli',
  'roman',
  'rondo',
  'rosen',
  'rotor',
  'route',
  'rowdy',
  'rubel',
  'rubin',
  'rucks',
  'ruder',
  'rudis',
  'rufer',
  'rufes',
  'rugby',
  'ruhms',
  'ruine',
  'ruins',
  'rumpf',
  'runen',
  'rupie',
  'russe',
  'ruten',
  'rußes',
  'räder',
  'ränge',
  'räson',
  'räten',
  'rätin',
  'röcke',
  'römer',
  'rüben',
  'rüden',
  'seato',
  'sparc',
  'swapo',
  'saals',
  'sache',
  'sacks',
  'sadat',
  'safes',
  'safte',
  'safts',
  'saite',
  'salat',
  'saldo',
  'salon',
  'salto',
  'salut',
  'salve',
  'samba',
  'samen',
  'samts',
  'sande',
  'sands',
  'santa',
  'sanyo',
  'sarde',
  'sargs',
  'satan',
  'satin',
  'satyr',
  'satze',
  'sauce',
  'saudi',
  'sauls',
  'saums',
  'sauna',
  'schaf',
  'schah',
  'scham',
  'schar',
  'schis',
  'schmu',
  'schoß',
  'schub',
  'schuh',
  'schur',
  'seele',
  'segel',
  'segen',
  'seher',
  'seide',
  'seiko',
  'seils',
  'seime',
  'seims',
  'seins',
  'seite',
  'sekte',
  'sekts',
  'semit',
  'senat',
  'senfs',
  'seoul',
  'sepps',
  'serbe',
  'seren',
  'serie',
  'serum',
  'sesam',
  'setup',
  'sexes',
  'sexte',
  'sexus',
  'orita',
  'shell',
  'shops',
  'shows',
  'sicht',
  'siebs',
  'siegs',
  'siele',
  'siels',
  'sigis',
  'silbe',
  'silke',
  'silos',
  'simon',
  'simse',
  'sinai',
  'sinns',
  'sinti',
  'sinus',
  'sippe',
  'sirup',
  'sitte',
  'skala',
  'skalp',
  'skats',
  'skier',
  'slawe',
  'slips',
  'slums',
  'smogs',
  'snobs',
  'socke',
  'sodas',
  'sodom',
  'sofas',
  'sofia',
  'soges',
  'sohle',
  'sohne',
  'sohns',
  'solde',
  'solds',
  'solei',
  'solon',
  'solos',
  'sonde',
  'songs',
  'sonja',
  'sonys',
  'sorbe',
  'sorte',
  'sound',
  'soßen',
  'spalt',
  'spans',
  'spant',
  'spatz',
  'speck',
  'speer',
  'spezi',
  'spieß',
  'spike',
  'spind',
  'spins',
  'spion',
  'spore',
  'sporn',
  'sport',
  'spots',
  'spott',
  'spree',
  'spreu',
  'sprit',
  'spuks',
  'spurt',
  'späne',
  'späße',
  'staat',
  'stabs',
  'stack',
  'stadt',
  'stall',
  'stamm',
  'stare',
  'stars',
  'start',
  'stasi',
  'staub',
  'staus',
  'steak',
  'stege',
  'stegs',
  'stein',
  'steiß',
  'stern',
  'steve',
  'stich',
  'stiel',
  'stier',
  'stift',
  'stile',
  'stils',
  'stirn',
  'stock',
  'stoff',
  'stola',
  'story',
  'stroh',
  'strom',
  'stube',
  'stuck',
  'stuhl',
  'stunk',
  'stunt',
  'sturm',
  'sturz',
  'stuss',
  'stute',
  'stäbe',
  'störs',
  'stöße',
  'stück',
  'suses',
  'sudan',
  'suite',
  'sumpf',
  'suppe',
  'sushi',
  'svens',
  'swing',
  'sylts',
  'syrer',
  'szene',
  'säbel',
  'säcke',
  'säfte',
  'sälen',
  'särge',
  'sätze',
  'säuen',
  'säule',
  'süden',
  'sülze',
  'sünde',
  'tbyte',
  'tabak',
  'tabus',
  'tadel',
  'tafts',
  'tages',
  'taiga',
  'takel',
  'takts',
  'taler',
  'tales',
  'talgs',
  'talks',
  'talon',
  'tands',
  'tange',
  'tango',
  'tangs',
  'tanja',
  'tanks',
  'tanne',
  'tante',
  'tapet',
  'tarif',
  'tasse',
  'tatar',
  'tatze',
  'taxen',
  'taxis',
  'teams',
  'teddy',
  'teeei',
  'teers',
  'tegel',
  'teich',
  'teige',
  'teigs',
  'teint',
  'telex',
  'tempo',
  'tenne',
  'tenor',
  'terme',
  'terms',
  'tests',
  'texas',
  'texel',
  'texte',
  'texts',
  'theke',
  'thema',
  'theos',
  'these',
  'thora',
  'thors',
  'thron',
  'tiber',
  'tibet',
  'ticks',
  'tiefs',
  'tiere',
  'tiers',
  'tiger',
  'tikis',
  'tilde',
  'tinas',
  'tinte',
  'tipps',
  'tirol',
  'tisch',
  'titan',
  'titel',
  'titos',
  'toast',
  'todes',
  'tofus',
  'togos',
  'token',
  'tokio',
  'tonen',
  'toner',
  'tones',
  'tonne',
  'tools',
  'topas',
  'topfs',
  'toren',
  'torfs',
  'torso',
  'torte',
  'torus',
  'totos',
  'trafo',
  'trakt',
  'trane',
  'trans',
  'traum',
  'treff',
  'trend',
  'trias',
  'trick',
  'trier',
  'trios',
  'tripe',
  'trips',
  'trogs',
  'troja',
  'troll',
  'tropf',
  'tross',
  'trost',
  'trott',
  'truhe',
  'trunk',
  'trupp',
  'tröge',
  'tuben',
  'tubus',
  'tuchs',
  'tuffe',
  'tuffs',
  'tulpe',
  'tumor',
  'tunis',
  'tunte',
  'tupel',
  'turin',
  'turme',
  'turms',
  'tusch',
  'tutor',
  'tutus',
  'typen',
  'typus',
  'täler',
  'tänze',
  'täter',
  'töpfe',
  'törin',
  'tücke',
  'tülls',
  'türen',
  'türke',
  'tüten',
  'unita',
  'udssr',
  'ufern',
  'ufers',
  'uhren',
  'ullas',
  'ulmen',
  'umbau',
  'umweg',
  'umzug',
  'unart',
  'unfug',
  'ungar',
  'union',
  'unmut',
  'unnas',
  'unrat',
  'unruh',
  'untat',
  'unzen',
  'urahn',
  'urals',
  'urans',
  'urins',
  'urnen',
  'usern',
  'users',
  'vamps',
  'vasen',
  'vater',
  'vatis',
  'velin',
  'venen',
  'venus',
  'verbs',
  'verdi',
  'verse',
  'vetos',
  'video',
  'viehs',
  'vikar',
  'villa',
  'viola',
  'viper',
  'viren',
  'virus',
  'vista',
  'visum',
  'vlies',
  'vogel',
  'vogts',
  'vokal',
  'volke',
  'volks',
  'volvo',
  'votum',
  'vulva',
  'väter',
  'vögte',
  'waage',
  'waben',
  'wachs',
  'waden',
  'wadis',
  'waffe',
  'wagon',
  'wahns',
  'waise',
  'walde',
  'walds',
  'walen',
  'wales',
  'walls',
  'walöl',
  'wange',
  'wanne',
  'wanst',
  'wanze',
  'warts',
  'warze',
  'watte',
  'watts',
  'weber',
  'weges',
  'weibe',
  'weins',
  'welpe',
  'werks',
  'werts',
  'wesen',
  'weser',
  'wespe',
  'weste',
  'wicke',
  'wiens',
  'wikis',
  'wilds',
  'wille',
  'willi',
  'willy',
  'winds',
  'winks',
  'wirte',
  'wirts',
  'witwe',
  'witze',
  'woche',
  'wodka',
  'wohls',
  'wolfs',
  'wolga',
  'wolke',
  'wonne',
  'worms',
  'worte',
  'worts',
  'wotan',
  'wrack',
  'wucht',
  'wulst',
  'wurfs',
  'wurms',
  'wurst',
  'wusts',
  'wälle',
  'wände',
  'wölfe',
  'würfe',
  'xenix',
  'xerox',
  'yacht',
  'yahoo',
  'yetis',
  'yogas',
  'yorks',
  'zahns',
  'zaire',
  'zange',
  'zaren',
  'zarge',
  'zarin',
  'zaume',
  'zaums',
  'zauns',
  'zebra',
  'zeche',
  'zehen',
  'zeile',
  'zelle',
  'zenit',
  'zeugs',
  'zicke',
  'ziege',
  'ziels',
  'zille',
  'zimts',
  'zinke',
  'zinks',
  'zinne',
  'zinns',
  'zinse',
  'zions',
  'zitat',
  'zitze',
  'zobel',
  'zofen',
  'zoffs',
  'zolls',
  'zonen',
  'zopfs',
  'zorns',
  'zorro',
  'zoten',
  'zuber',
  'zucht',
  'zuges',
  'zunft',
  'zunge',
  'zuruf',
  'zutat',
  'zutun',
  'zuzug',
  'zweck',
  'zweig',
  'zwerg',
  'zwirn',
  'zwist',
  'zyste',
  'zähne',
  'zäsur',
  'zäune',
  'zölle',
  'zöpfe',
  'zügen',
  'aalen',
  'aalst',
  'aalte',
  'abbat',
  'abbog',
  'abgab',
  'abhat',
  'abhob',
  'abkam',
  'ablag',
  'abmaß',
  'absah',
  'absaß',
  'abtat',
  'abtue',
  'abtun',
  'abtut',
  'abwog',
  'abzgl',
  'abzog',
  'achte',
  'ackre',
  'adele',
  'adeln',
  'adelt',
  'adieu',
  'adlig',
  'adrig',
  'aerob',
  'affig',
  'agile',
  'ahmen',
  'ahmst',
  'ahmte',
  'ahnde',
  'ahnen',
  'ahnst',
  'ahnte',
  'aktiv',
  'akute',
  'albre',
  'alert',
  'alias',
  'alien',
  'allem',
  'allen',
  'aller',
  'alles',
  'allwo',
  'allzu',
  'alpha',
  'alpin',
  'altem',
  'alten',
  'alter',
  'altes',
  'altre',
  'amten',
  'amtet',
  'anale',
  'anbei',
  'anbot',
  'andre',
  'angab',
  'angle',
  'angst',
  'anhob',
  'ankam',
  'ankre',
  'ansah',
  'antat',
  'antik',
  'antue',
  'antun',
  'antut',
  'anzog',
  'apart',
  'argem',
  'argen',
  'arger',
  'arges',
  'aride',
  'armem',
  'armen',
  'armer',
  'armes',
  'arten',
  'artet',
  'artig',
  'atmen',
  'atmet',
  'außen',
  'außer',
  'axial',
  'aßest',
  'backe',
  'backt',
  'baden',
  'badet',
  'bahne',
  'bahnt',
  'balge',
  'balgt',
  'balle',
  'ballt',
  'banal',
  'bange',
  'bangt',
  'banne',
  'bannt',
  'barem',
  'baren',
  'barer',
  'bares',
  'bargt',
  'barst',
  'basal',
  'baten',
  'batet',
  'batst',
  'bauen',
  'baust',
  'baute',
  'beben',
  'bebst',
  'bebte',
  'beeng',
  'begab',
  'begib',
  'behob',
  'beide',
  'beige',
  'beiße',
  'beißt',
  'bekam',
  'belle',
  'bellt',
  'belog',
  'belud',
  'bemaß',
  'berge',
  'bergt',
  'berät',
  'besah',
  'besaß',
  'beste',
  'beten',
  'betet',
  'beton',
  'bette',
  'beuge',
  'beugt',
  'beute',
  'bevor',
  'beweg',
  'bewog',
  'bezog',
  'biege',
  'biegt',
  'biete',
  'bilde',
  'binde',
  'binär',
  'birgt',
  'bisst',
  'bitte',
  'blank',
  'blase',
  'blass',
  'blast',
  'blaue',
  'bleib',
  'blich',
  'blick',
  'blieb',
  'blies',
  'blind',
  'block',
  'blond',
  'bloße',
  'blute',
  'blähe',
  'bläht',
  'bläst',
  'blöde',
  'blöke',
  'blökt',
  'blühe',
  'blüht',
  'bocke',
  'bockt',
  'bogen',
  'bogst',
  'bohre',
  'bohrt',
  'boome',
  'boomt',
  'boote',
  'borge',
  'borgt',
  'boten',
  'botet',
  'botst',
  'boxen',
  'boxte',
  'brach',
  'brate',
  'braue',
  'braun',
  'braut',
  'brave',
  'breit',
  'brems',
  'brenn',
  'brich',
  'briet',
  'bring',
  'bräun',
  'brühe',
  'brüht',
  'brüsk',
  'brüte',
  'buche',
  'bucht',
  'buhen',
  'buhle',
  'buhlt',
  'buhst',
  'buhte',
  'buken',
  'bukst',
  'bumse',
  'bumst',
  'bunte',
  'bäckt',
  'bäumt',
  'böige',
  'bösem',
  'bösen',
  'böser',
  'böses',
  'böten',
  'bötet',
  'bücke',
  'bückt',
  'bügle',
  'büken',
  'bükst',
  'bürde',
  'bürge',
  'bürgt',
  'büßen',
  'büßte',
  'chice',
  'circa',
  'coate',
  'coole',
  'creme',
  'cremt',
  'dabei',
  'dafür',
  'daher',
  'dahin',
  'damit',
  'danke',
  'dankt',
  'daran',
  'darbe',
  'darbt',
  'darin',
  'darum',
  'dasaß',
  'daure',
  'davon',
  'davor',
  'deale',
  'dealt',
  'debil',
  'decke',
  'deckt',
  'dehne',
  'dehnt',
  'deine',
  'delta',
  'denen',
  'denke',
  'denkt',
  'derbe',
  'deren',
  'derer',
  'desto',
  'deute',
  'devot',
  'dicht',
  'dicke',
  'diene',
  'dient',
  'diese',
  'dimme',
  'dimmt',
  'doofe',
  'dorre',
  'dorrt',
  'drall',
  'drang',
  'drauf',
  'drehe',
  'dreht',
  'drein',
  'dritt',
  'drohe',
  'droht',
  'druck',
  'dröge',
  'drück',
  'duale',
  'dubio',
  'ducke',
  'duckt',
  'dufte',
  'dulde',
  'dumme',
  'dumpf',
  'durch',
  'duzen',
  'duzte',
  'dämme',
  'dämmt',
  'dörre',
  'dörrt',
  'dösen',
  'döste',
  'dünge',
  'düngt',
  'dünkt',
  'dünne',
  'dürfe',
  'dürft',
  'dürre',
  'ebays',
  'ebben',
  'ebbst',
  'ebbte',
  'ebene',
  'ebnen',
  'ebnet',
  'echte',
  'ecken',
  'eckig',
  'eckst',
  'eckte',
  'edlem',
  'edlen',
  'edler',
  'edles',
  'ehren',
  'ehrst',
  'ehrte',
  'eiche',
  'eicht',
  'eifre',
  'eigen',
  'eigne',
  'eilen',
  'eilig',
  'eilst',
  'eilte',
  'einem',
  'einen',
  'einer',
  'eines',
  'einig',
  'einst',
  'einte',
  'eisig',
  'eitel',
  'eitle',
  'eitre',
  'ekele',
  'ekeln',
  'ekelt',
  'eklig',
  'elend',
  'elfte',
  'empor',
  'emsig',
  'enden',
  'endet',
  'engem',
  'engen',
  'enger',
  'enges',
  'engst',
  'engte',
  'enorm',
  'entre',
  'erbat',
  'erben',
  'erbot',
  'erbst',
  'erbte',
  'erden',
  'erdet',
  'erdig',
  'ergab',
  'erhob',
  'erkor',
  'erlag',
  'ernst',
  'ernte',
  'errät',
  'erste',
  'erwog',
  'erzog',
  'essen',
  'etwas',
  'eurem',
  'euren',
  'eurer',
  'eures',
  'ewige',
  'exakt',
  'extra',
  'fache',
  'facht',
  'facto',
  'fadem',
  'faden',
  'fader',
  'fades',
  'fahle',
  'fahre',
  'fahrt',
  'faire',
  'falbe',
  'falle',
  'falls',
  'fallt',
  'falte',
  'falze',
  'falzt',
  'famos',
  'fange',
  'fangt',
  'fasle',
  'fasse',
  'fasst',
  'faste',
  'fatal',
  'faule',
  'fault',
  'faxen',
  'faxte',
  'fedre',
  'fegen',
  'fegst',
  'fegte',
  'fehle',
  'fehlt',
  'feige',
  'feile',
  'feilt',
  'feind',
  'feine',
  'feire',
  'ferne',
  'fesch',
  'feste',
  'fette',
  'feure',
  'ficht',
  'ficke',
  'fickt',
  'fidel',
  'fiele',
  'fielt',
  'fiese',
  'filme',
  'filmt',
  'filze',
  'filzt',
  'final',
  'finde',
  'fingt',
  'finit',
  'fitte',
  'fixem',
  'fixen',
  'fixer',
  'fixes',
  'fixte',
  'flach',
  'flaue',
  'flehe',
  'fleht',
  'flieg',
  'flieh',
  'flink',
  'flogt',
  'floht',
  'floss',
  'flott',
  'flugs',
  'flute',
  'fläze',
  'fläzt',
  'flöge',
  'flöhe',
  'flöht',
  'flöte',
  'flöße',
  'flößt',
  'focht',
  'folge',
  'folgt',
  'foppe',
  'foppt',
  'forma',
  'forme',
  'formt',
  'frage',
  'fragt',
  'franz',
  'fraßt',
  'frech',
  'freie',
  'freit',
  'fremd',
  'freue',
  'freut',
  'friss',
  'frohe',
  'fromm',
  'frort',
  'fräse',
  'fräst',
  'fräße',
  'fröne',
  'frönt',
  'frühe',
  'fuhrt',
  'funke',
  'funkt',
  'furze',
  'furzt',
  'fußen',
  'fußte',
  'fädle',
  'fähig',
  'fährt',
  'fälle',
  'fällt',
  'fände',
  'fängt',
  'färbe',
  'färbt',
  'föhne',
  'föhnt',
  'fügen',
  'fügst',
  'fügte',
  'fühle',
  'fühlt',
  'führe',
  'führt',
  'fülle',
  'füllt',
  'fünft',
  'ggmbh',
  'gaben',
  'gable',
  'gabst',
  'gaffe',
  'gafft',
  'gamma',
  'ganze',
  'garem',
  'garen',
  'garer',
  'gares',
  'garni',
  'gebar',
  'geben',
  'gebot',
  'gegen',
  'gehen',
  'gehst',
  'geige',
  'geigt',
  'geile',
  'geize',
  'geizt',
  'gelbe',
  'gelle',
  'gellt',
  'gelte',
  'gemäß',
  'genau',
  'genug',
  'gerbe',
  'gerbt',
  'gerne',
  'gerät',
  'gesät',
  'getan',
  'geölt',
  'geübt',
  'gibst',
  'giere',
  'giert',
  'gieße',
  'gießt',
  'gifte',
  'ginge',
  'gingt',
  'glatt',
  'glaub',
  'glich',
  'glitt',
  'glühe',
  'glüht',
  'gosst',
  'grabe',
  'grabt',
  'grase',
  'grast',
  'graue',
  'graut',
  'greif',
  'grell',
  'griff',
  'grill',
  'grobe',
  'große',
  'grubt',
  'gräbt',
  'gräme',
  'grämt',
  'gröle',
  'grölt',
  'grüne',
  'grünt',
  'grüße',
  'grüßt',
  'gucke',
  'guckt',
  'gutem',
  'guten',
  'guter',
  'gutes',
  'gäben',
  'gäbst',
  'gähne',
  'gähnt',
  'gälte',
  'gären',
  'gärst',
  'gärte',
  'gönne',
  'gönnt',
  'gütig',
  'haben',
  'habet',
  'hacke',
  'hackt',
  'hadre',
  'hafte',
  'hager',
  'hagle',
  'haken',
  'hakst',
  'hakte',
  'halbe',
  'halft',
  'halle',
  'hallo',
  'hallt',
  'halte',
  'hapre',
  'harke',
  'harkt',
  'harre',
  'harrt',
  'harte',
  'hasse',
  'hasst',
  'haste',
  'hatte',
  'hauch',
  'hauen',
  'hause',
  'haust',
  'haute',
  'heben',
  'heble',
  'hebst',
  'hecke',
  'heckt',
  'hefte',
  'hegen',
  'hegst',
  'hegte',
  'hehle',
  'hehlt',
  'heile',
  'heilt',
  'heize',
  'heizt',
  'heiße',
  'heißt',
  'helfe',
  'helft',
  'helle',
  'hellt',
  'hemme',
  'hemmt',
  'herab',
  'heran',
  'herbe',
  'herum',
  'herzu',
  'hetze',
  'hetzt',
  'heuer',
  'heule',
  'heult',
  'heure',
  'heute',
  'hexen',
  'hexte',
  'hielt',
  'hieve',
  'hievt',
  'hieße',
  'hießt',
  'hilft',
  'hinab',
  'hinan',
  'hinge',
  'hingt',
  'hinke',
  'hinkt',
  'hinzu',
  'hisse',
  'hisst',
  'hobel',
  'hoben',
  'hoble',
  'hobst',
  'hocke',
  'hockt',
  'hoffe',
  'hofft',
  'hohem',
  'hohen',
  'hoher',
  'hohes',
  'hohle',
  'holde',
  'holen',
  'holst',
  'holte',
  'holze',
  'holzt',
  'hopse',
  'hopst',
  'horch',
  'horte',
  'human',
  'humid',
  'hupen',
  'hupst',
  'hupte',
  'huren',
  'hurra',
  'hurst',
  'hurte',
  'huste',
  'häkle',
  'hänge',
  'hängt',
  'härte',
  'hätte',
  'häufe',
  'häuft',
  'höher',
  'höhlt',
  'höhne',
  'höhnt',
  'hören',
  'hörig',
  'hörst',
  'hörte',
  'hüben',
  'hülle',
  'hüllt',
  'hüpfe',
  'hüpft',
  'hüten',
  'hütet',
  'ideal',
  'igele',
  'igeln',
  'igelt',
  'ihnen',
  'ihrem',
  'ihren',
  'ihrer',
  'ihres',
  'imkre',
  'immer',
  'immun',
  'impfe',
  'impft',
  'indem',
  'indes',
  'infam',
  'innen',
  'innig',
  'intim',
  'intus',
  'irden',
  'irrem',
  'irren',
  'irrer',
  'irres',
  'irrig',
  'irrst',
  'irrte',
  'jagen',
  'jagst',
  'jagte',
  'jaule',
  'jault',
  'jedem',
  'jeden',
  'jeder',
  'jedes',
  'jeher',
  'jenem',
  'jenen',
  'jener',
  'jenes',
  'jette',
  'jetzt',
  'jobbe',
  'jobbt',
  'jogge',
  'joggt',
  'juble',
  'jucke',
  'juckt',
  'junge',
  'jähem',
  'jähen',
  'jäher',
  'jähes',
  'jähre',
  'jährt',
  'jäten',
  'jätet',
  'kable',
  'kahle',
  'kalte',
  'kamen',
  'kamst',
  'kante',
  'kappa',
  'kappe',
  'kappt',
  'karge',
  'karre',
  'karrt',
  'kauen',
  'kauer',
  'kaufe',
  'kauft',
  'kaure',
  'kaust',
  'kaute',
  'kecke',
  'kegel',
  'kegle',
  'kehre',
  'kehrt',
  'keife',
  'keift',
  'keile',
  'keilt',
  'keime',
  'keimt',
  'keine',
  'keins',
  'kenne',
  'kennt',
  'kerbe',
  'kerbt',
  'kesse',
  'kette',
  'keuch',
  'kicke',
  'kickt',
  'kille',
  'killt',
  'kippe',
  'kippt',
  'kitte',
  'klage',
  'klagt',
  'klamm',
  'klang',
  'klapp',
  'klare',
  'klaue',
  'klaut',
  'klebe',
  'klebt',
  'klein',
  'klemm',
  'klick',
  'kling',
  'klipp',
  'klirr',
  'klone',
  'klont',
  'klopf',
  'kluge',
  'kläff',
  'kläre',
  'klärt',
  'klöne',
  'klönt',
  'knack',
  'knall',
  'knapp',
  'knaps',
  'knarr',
  'kneif',
  'knete',
  'knick',
  'knien',
  'kniet',
  'kniff',
  'knips',
  'knote',
  'knurr',
  'knöpf',
  'knüpf',
  'koche',
  'kocht',
  'kokse',
  'kokst',
  'komme',
  'kommt',
  'koste',
  'kotze',
  'kotzt',
  'kraft',
  'krame',
  'kramt',
  'krank',
  'krass',
  'kratz',
  'kraus',
  'krieg',
  'kroch',
  'kross',
  'krude',
  'krumm',
  'krähe',
  'kräht',
  'kröne',
  'krönt',
  'kugle',
  'kuren',
  'kurst',
  'kurte',
  'kurve',
  'kurvt',
  'kurze',
  'kämen',
  'kämme',
  'kämmt',
  'kämst',
  'könne',
  'könnt',
  'köpfe',
  'köpft',
  'kühle',
  'kühlt',
  'kühne',
  'künde',
  'küren',
  'kürst',
  'kürte',
  'kürze',
  'kürzt',
  'küsse',
  'küsst',
  'laben',
  'labil',
  'labst',
  'labte',
  'lache',
  'lacht',
  'laden',
  'ladet',
  'lagen',
  'lagre',
  'lagst',
  'lahme',
  'lahmt',
  'lalle',
  'lallt',
  'lande',
  'lange',
  'langt',
  'lasen',
  'laser',
  'lasre',
  'lasse',
  'lasst',
  'laste',
  'laude',
  'lauem',
  'lauen',
  'lauer',
  'laues',
  'laufe',
  'lauft',
  'lauge',
  'laugt',
  'laure',
  'lause',
  'laust',
  'laute',
  'laxem',
  'laxen',
  'laxer',
  'laxes',
  'lease',
  'least',
  'leben',
  'lebst',
  'lebte',
  'lecke',
  'leckt',
  'ledig',
  'leere',
  'leert',
  'legal',
  'legen',
  'leger',
  'legst',
  'legte',
  'lehne',
  'lehnt',
  'lehre',
  'lehrt',
  'leibt',
  'leide',
  'leihe',
  'leiht',
  'leime',
  'leimt',
  'leise',
  'leite',
  'lenke',
  'lenkt',
  'lerne',
  'lernt',
  'lesen',
  'letal',
  'licht',
  'liebe',
  'liebt',
  'liefe',
  'lieft',
  'liege',
  'liegt',
  'lieht',
  'liest',
  'ließe',
  'ließt',
  'lifte',
  'linde',
  'linke',
  'links',
  'linkt',
  'liste',
  'litte',
  'loben',
  'lobst',
  'lobte',
  'loche',
  'locht',
  'locke',
  'lockt',
  'lodre',
  'logen',
  'logge',
  'loggt',
  'logst',
  'lohne',
  'lohnt',
  'lokal',
  'losem',
  'losen',
  'loser',
  'loses',
  'loste',
  'loten',
  'lotet',
  'lotse',
  'lotst',
  'loyal',
  'luden',
  'ludet',
  'ludst',
  'lugen',
  'lugst',
  'lugte',
  'lynch',
  'lädst',
  'lägen',
  'lägst',
  'lähme',
  'lähmt',
  'längs',
  'lärme',
  'lärmt',
  'lässt',
  'läuft',
  'läute',
  'löhne',
  'löhnt',
  'lösen',
  'löste',
  'löten',
  'lötet',
  'lüfte',
  'lügen',
  'lügst',
  'mache',
  'macht',
  'madig',
  'mager',
  'magst',
  'mahle',
  'mahlt',
  'mahne',
  'mahnt',
  'malen',
  'malst',
  'malte',
  'manch',
  'matte',
  'maule',
  'mault',
  'maure',
  'mehre',
  'mehrt',
  'meide',
  'meine',
  'meins',
  'meint',
  'meist',
  'melde',
  'melke',
  'melkt',
  'menge',
  'mengt',
  'merke',
  'merkt',
  'messe',
  'messt',
  'miaue',
  'miaut',
  'miese',
  'miete',
  'milde',
  'minus',
  'misch',
  'misse',
  'misst',
  'miste',
  'mixen',
  'mixte',
  'mobbe',
  'mobbt',
  'mobil',
  'modre',
  'mogle',
  'molar',
  'mopse',
  'mopst',
  'morde',
  'moser',
  'mosre',
  'moste',
  'motze',
  'motzt',
  'munde',
  'murre',
  'murrt',
  'musst',
  'muten',
  'mutet',
  'mutig',
  'mähen',
  'mähst',
  'mähte',
  'mäste',
  'mäßig',
  'mögen',
  'möget',
  'müdem',
  'müden',
  'müder',
  'müdes',
  'mühen',
  'mühst',
  'mühte',
  'münde',
  'münze',
  'münzt',
  'mürbe',
  'müsse',
  'müsst',
  'müßig',
  'nackt',
  'nagen',
  'nagle',
  'nagst',
  'nagte',
  'nahem',
  'nahen',
  'naher',
  'nahes',
  'nahmt',
  'nahst',
  'nahte',
  'naive',
  'narre',
  'narrt',
  'nasse',
  'nativ',
  'neben',
  'nebst',
  'necke',
  'neckt',
  'nehme',
  'nehmt',
  'neide',
  'neige',
  'neigt',
  'nenne',
  'nennt',
  'nerve',
  'nervt',
  'nette',
  'netto',
  'neuem',
  'neuen',
  'neuer',
  'neues',
  'neunt',
  'neust',
  'nicht',
  'nicke',
  'nickt',
  'niese',
  'niest',
  'niete',
  'nimmt',
  'nippe',
  'nippt',
  'niste',
  'nobel',
  'noble',
  'norme',
  'nutze',
  'nutzt',
  'nähen',
  'näher',
  'nähme',
  'nähmt',
  'nähre',
  'nährt',
  'nähst',
  'nähte',
  'näsle',
  'nölen',
  'nölst',
  'nölte',
  'nötig',
  'nütze',
  'nützt',
  'obere',
  'obige',
  'oblag',
  'ocker',
  'offen',
  'omega',
  'opfer',
  'opfre',
  'orale',
  'ordne',
  'ordre',
  'orten',
  'ortet',
  'ovale',
  'paare',
  'paart',
  'packe',
  'packt',
  'paffe',
  'pafft',
  'pappe',
  'pappt',
  'parat',
  'parke',
  'parkt',
  'passe',
  'passt',
  'patze',
  'patzt',
  'pauke',
  'paukt',
  'pause',
  'paust',
  'peile',
  'peilt',
  'pelle',
  'pellt',
  'perle',
  'perlt',
  'petze',
  'petzt',
  'pfiff',
  'piano',
  'picke',
  'pickt',
  'piepe',
  'piept',
  'piken',
  'pikse',
  'pikst',
  'pikte',
  'pisse',
  'pisst',
  'plage',
  'plagt',
  'plane',
  'plant',
  'platt',
  'platz',
  'plump',
  'poche',
  'pocht',
  'pokre',
  'polar',
  'polig',
  'porös',
  'prall',
  'preis',
  'prell',
  'pries',
  'prima',
  'probe',
  'probt',
  'prost',
  'präge',
  'prägt',
  'prüde',
  'prüfe',
  'prüft',
  'pudre',
  'pumpe',
  'pumpt',
  'purem',
  'puren',
  'purer',
  'pures',
  'pushe',
  'pusht',
  'puste',
  'putze',
  'putzt',
  'quasi',
  'quell',
  'quill',
  'quitt',
  'quäle',
  'quält',
  'radle',
  'raffe',
  'rafft',
  'ragen',
  'ragst',
  'ragte',
  'ramme',
  'rammt',
  'rangt',
  'ranke',
  'rankt',
  'rapid',
  'rarem',
  'raren',
  'rarer',
  'rares',
  'rarst',
  'rasch',
  'rasen',
  'raste',
  'raten',
  'ratet',
  'ratio',
  'raube',
  'raubt',
  'rauem',
  'rauen',
  'rauer',
  'raues',
  'raufe',
  'rauft',
  'raune',
  'raunt',
  'raust',
  'raute',
  'reale',
  'reche',
  'recht',
  'recke',
  'reckt',
  'reden',
  'redet',
  'reell',
  'regem',
  'regen',
  'reger',
  'reges',
  'regle',
  'regne',
  'regst',
  'regte',
  'reibe',
  'reibt',
  'reich',
  'reife',
  'reift',
  'reihe',
  'reiht',
  'reime',
  'reimt',
  'reine',
  'reise',
  'reist',
  'reite',
  'reize',
  'reizt',
  'reiße',
  'reißt',
  'relax',
  'remis',
  'renne',
  'rennt',
  'rette',
  'reuig',
  'riebe',
  'riebt',
  'riefe',
  'rieft',
  'riete',
  'rigid',
  'ringe',
  'rings',
  'ringt',
  'rinne',
  'rinnt',
  'risst',
  'ritte',
  'ritze',
  'ritzt',
  'robbe',
  'robbt',
  'rocht',
  'roden',
  'rodet',
  'rodle',
  'rohem',
  'rohen',
  'roher',
  'rohes',
  'rolle',
  'rollt',
  'rosig',
  'roste',
  'rotem',
  'roten',
  'roter',
  'rotes',
  'rotte',
  'rotze',
  'rotzt',
  'rudre',
  'rufen',
  'rufst',
  'ruhen',
  'ruhig',
  'ruhst',
  'ruhte',
  'rumor',
  'runde',
  'rupfe',
  'rupft',
  'rußig',
  'räche',
  'rächt',
  'räkle',
  'rätst',
  'räume',
  'räumt',
  'röche',
  'röcht',
  'röhre',
  'röhrt',
  'röste',
  'röter',
  'rücke',
  'rückt',
  'rügen',
  'rügst',
  'rügte',
  'rühme',
  'rühmt',
  'rühre',
  'rührt',
  'rülps',
  'rümpf',
  'rüste',
  'sacht',
  'sacke',
  'sackt',
  'sagen',
  'sagst',
  'sagte',
  'sahen',
  'sahne',
  'sahnt',
  'sahst',
  'salbe',
  'salbt',
  'salze',
  'salzt',
  'sanft',
  'sangt',
  'sankt',
  'sannt',
  'satte',
  'sauen',
  'sauer',
  'saufe',
  'sauft',
  'sauge',
  'saugt',
  'saure',
  'sause',
  'saust',
  'saute',
  'saßen',
  'scann',
  'schal',
  'schau',
  'scher',
  'scheu',
  'schob',
  'schon',
  'schor',
  'schuf',
  'schön',
  'sechs',
  'segle',
  'segne',
  'sehen',
  'sehne',
  'sehnt',
  'seien',
  'seife',
  'seift',
  'seile',
  'seilt',
  'seine',
  'seist',
  'selbe',
  'selig',
  'sende',
  'senge',
  'sengt',
  'senil',
  'senke',
  'senkt',
  'setze',
  'setzt',
  'siebe',
  'siebt',
  'siech',
  'siede',
  'siege',
  'siegt',
  'siehe',
  'sieht',
  'sieze',
  'siezt',
  'sigma',
  'singe',
  'singt',
  'sinke',
  'sinkt',
  'sinne',
  'sinnt',
  'sitze',
  'sitzt',
  'smart',
  'sofft',
  'softe',
  'sogar',
  'sogen',
  'sogst',
  'solch',
  'solid',
  'solle',
  'sollt',
  'somit',
  'sonne',
  'sonnt',
  'sonor',
  'sonst',
  'sooft',
  'sorge',
  'sorgt',
  'sowie',
  'spann',
  'spare',
  'spart',
  'spaße',
  'spaßt',
  'speie',
  'speit',
  'sperr',
  'spiel',
  'spien',
  'spiet',
  'spitz',
  'spröd',
  'sprüh',
  'spuck',
  'spuke',
  'spukt',
  'spule',
  'spult',
  'spute',
  'spähe',
  'späht',
  'späte',
  'spüle',
  'spült',
  'spüre',
  'spürt',
  'stach',
  'stahl',
  'stakt',
  'stand',
  'stank',
  'starb',
  'stark',
  'starr',
  'statt',
  'staue',
  'staut',
  'steck',
  'stehe',
  'steht',
  'steif',
  'steig',
  'steil',
  'stell',
  'stete',
  'stets',
  'stieg',
  'stieß',
  'still',
  'stirb',
  'stolz',
  'stopp',
  'stoße',
  'stoßt',
  'straf',
  'stufe',
  'stuft',
  'stumm',
  'sture',
  'störe',
  'stört',
  'stößt',
  'stülp',
  'suche',
  'sucht',
  'sudle',
  'sulze',
  'sulzt',
  'summa',
  'summe',
  'summt',
  'super',
  'surfe',
  'surft',
  'surre',
  'surrt',
  'säend',
  'sägen',
  'sägst',
  'sägte',
  'sähen',
  'sähet',
  'sämig',
  'säten',
  'sätet',
  'säuft',
  'säuge',
  'säugt',
  'säume',
  'säumt',
  'säure',
  'säßen',
  'säßet',
  'söhne',
  'söhnt',
  'sühne',
  'sühnt',
  'süßem',
  'süßen',
  'süßer',
  'süßes',
  'süßte',
  'tadle',
  'tafel',
  'tafle',
  'tagen',
  'tagst',
  'tagte',
  'takle',
  'takte',
  'tanke',
  'tankt',
  'tanze',
  'tanzt',
  'tappe',
  'tappt',
  'tapse',
  'tapst',
  'tarne',
  'tarnt',
  'taste',
  'taten',
  'tatet',
  'tatst',
  'taube',
  'tauen',
  'taufe',
  'tauft',
  'tauge',
  'taugt',
  'taust',
  'taute',
  'teere',
  'teert',
  'teile',
  'teils',
  'teilt',
  'teste',
  'teuer',
  'teure',
  'theta',
  'ticke',
  'tickt',
  'tiefe',
  'tilge',
  'tilgt',
  'tippe',
  'tippt',
  'title',
  'toben',
  'tobst',
  'tobte',
  'tolle',
  'tollt',
  'tosen',
  'toste',
  'total',
  'totem',
  'toten',
  'toter',
  'totes',
  'trabe',
  'trabt',
  'traft',
  'trage',
  'tragt',
  'tramp',
  'trank',
  'traue',
  'traut',
  'treib',
  'trete',
  'treue',
  'trieb',
  'triff',
  'trink',
  'trist',
  'tritt',
  'trogt',
  'trotz',
  'trugt',
  'träfe',
  'träge',
  'trägt',
  'träne',
  'tränt',
  'träum',
  'tröte',
  'trübe',
  'trübt',
  'trüge',
  'trügt',
  'tuend',
  'tunke',
  'tunkt',
  'tupfe',
  'tupft',
  'turne',
  'turnt',
  'tuten',
  'tutet',
  'täfle',
  'täten',
  'tätet',
  'tätig',
  'tönen',
  'tönst',
  'tönte',
  'töten',
  'tötet',
  'türme',
  'türmt',
  'ulken',
  'ulkig',
  'ulkst',
  'ulkte',
  'umgab',
  'umher',
  'umhin',
  'umkam',
  'umsah',
  'umtue',
  'umtun',
  'umtut',
  'umzog',
  'unbar',
  'ungut',
  'unken',
  'unkst',
  'unkte',
  'unser',
  'unsre',
  'unten',
  'unter',
  'untot',
  'uralt',
  'urban',
  'urige',
  'vagem',
  'vagen',
  'vager',
  'vages',
  'vegan',
  'vergl',
  'versa',
  'viele',
  'viert',
  'vital',
  'volle',
  'vorab',
  'voran',
  'vorig',
  'vorne',
  'vögel',
  'vögle',
  'wache',
  'wacht',
  'wagen',
  'wagst',
  'wagte',
  'wahre',
  'wahrt',
  'walle',
  'wallt',
  'walte',
  'walze',
  'walzt',
  'wanke',
  'wankt',
  'warbt',
  'waren',
  'warft',
  'warme',
  'warne',
  'warnt',
  'warst',
  'warte',
  'warum',
  'wasch',
  'waten',
  'watet',
  'weben',
  'webst',
  'webte',
  'wecke',
  'weckt',
  'wedel',
  'weder',
  'wedle',
  'wegen',
  'wehen',
  'wehre',
  'wehrt',
  'wehst',
  'wehte',
  'weich',
  'weide',
  'weihe',
  'weiht',
  'weile',
  'weilt',
  'weine',
  'weint',
  'weise',
  'weist',
  'weite',
  'weiße',
  'weißt',
  'welch',
  'welke',
  'welkt',
  'welle',
  'wellt',
  'wende',
  'wenig',
  'werbe',
  'werbt',
  'werde',
  'werfe',
  'werft',
  'werke',
  'werkt',
  'werte',
  'wette',
  'wetze',
  'wetzt',
  'wiche',
  'wichs',
  'wicht',
  'wider',
  'widme',
  'widre',
  'wiege',
  'wiegt',
  'wiese',
  'wieso',
  'wiest',
  'wilde',
  'winde',
  'winke',
  'winkt',
  'wippe',
  'wippt',
  'wirbt',
  'wirft',
  'wirke',
  'wirkt',
  'wirre',
  'wirrt',
  'wirst',
  'wisch',
  'wisse',
  'wisst',
  'wobei',
  'wofür',
  'wogen',
  'wogst',
  'woher',
  'wohin',
  'wohle',
  'wohne',
  'wohnt',
  'wolle',
  'wollt',
  'womit',
  'woran',
  'worin',
  'worum',
  'wovon',
  'wovor',
  'wrang',
  'wuchs',
  'wunde',
  'wurde',
  'wurme',
  'wurmt',
  'wusch',
  'wusel',
  'wusle',
  'wägen',
  'wägst',
  'wähle',
  'wählt',
  'wähne',
  'wähnt',
  'währt',
  'wälze',
  'wälzt',
  'wären',
  'wärme',
  'wärmt',
  'wärst',
  'wögen',
  'wöget',
  'wölbe',
  'wölbt',
  'wühle',
  'wühlt',
  'würde',
  'würge',
  'würgt',
  'würze',
  'würzt',
  'wüste',
  'wüten',
  'wütet',
  'zahle',
  'zahlt',
  'zahme',
  'zanke',
  'zankt',
  'zapfe',
  'zapft',
  'zarte',
  'zehnt',
  'zehre',
  'zehrt',
  'zeige',
  'zeigt',
  'zelte',
  'zerre',
  'zerrt',
  'zeter',
  'zetre',
  'zeuge',
  'zeugt',
  'ziehe',
  'zieht',
  'ziele',
  'zielt',
  'zieme',
  'ziemt',
  'ziere',
  'ziert',
  'zirka',
  'zirpe',
  'zirpt',
  'zisch',
  'zivil',
  'zocke',
  'zockt',
  'zogen',
  'zogst',
  'zolle',
  'zollt',
  'zoome',
  'zoomt',
  'zotig',
  'zucke',
  'zuckt',
  'zudem',
  'zugab',
  'zugig',
  'zumal',
  'zumba',
  'zupfe',
  'zupft',
  'zusah',
  'zuvor',
  'zuzog',
  'zwack',
  'zwang',
  'zweit',
  'zwick',
  'zwing',
  'zwölf',
  'zähem',
  'zähen',
  'zäher',
  'zähes',
  'zähle',
  'zählt',
  'zähme',
  'zähmt',
  'zähst',
  'zögen',
  'zöger',
  'zöget',
  'zögre',
  'zücke',
  'zückt',
  'zügel',
  'zügig',
  'zügle',
  'zünde',
  'zürne',
  'zürnt',
  'äbten',
  'äcker',
  'äffin',
  'ägide',
  'ähren',
  'ämter',
  'äonen',
  'äpfel',
  'ärmel',
  'ärzte',
  'äther',
  'äthyl',
  'äxten',
  'ölung',
  'übeln',
  'übels',
  'übens',
  'übung',
  'ächte',
  'ächze',
  'ächzt',
  'äffen',
  'äffst',
  'äffte',
  'ähnle',
  'älter',
  'ändre',
  'ärger',
  'ärgre',
  'ärmer',
  'äsend',
  'äsest',
  'ästen',
  'ästet',
  'ätsch',
  'ätzen',
  'ätzte',
  'äugen',
  'äugst',
  'äugte',
  'äußre',
  'äßest',
  'ödere',
  'ödest',
  'öffne',
  'öfter',
  'ölend',
  'ölige',
  'ölten',
  'öltet',
  'übend',
  'übers',
  'üblem',
  'üblen',
  'übler',
  'übles',
  'übrig',
  'übten',
  'übtet',
  'üppig',
]
