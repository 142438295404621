import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="So funktioniert's:"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p>
        Errate das Wördl des Tages in 6 Versuchen. Nach jedem Versuch wird
        mit Farben angezeigt, wie nah dein Wort der Lösung war:
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" />
        <Cell value="L" />
        <Cell value="U" status="correct"/>
        <Cell value="B" />
        <Cell value="B" />
      </div>
      <p>Das U kommt im Wort an der richtigen Stelle vor.</p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="A" status="present" />
        <Cell value="M" />
        <Cell value="P" />
        <Cell value="E" />
        <Cell value="L" />
      </div>
      <p>Das A kommt im Wort vor, jedoch an anderer Stelle.</p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="F" />
        <Cell value="I" />
        <Cell value="S" />
        <Cell value="C" />
        <Cell value="H" status="absent" />
      </div>
      <p>Das H kommt nicht im Wort vor.</p>

      <p className="mt-4">
        Jeden Tag um Mitternacht wird ein neues Wördl freigeschaltet. Hast du
        Feedback oder willst dein Ergebnis teilen? Schreibe uns unter{'woerdl@web.de'}
        <a
          href="woerdl@web.de"
          className="underline font-bold"
        >
        </a>
        .
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        Wördl ist ein deutschsprachiger Klon von{' '}
        <a
          href="https://www.powerlanguage.co.uk/wordle/"
          className="underline font-bold"
        >
          Wordle
        </a>
        . Den Quellcode findet ihr{' '}
        <a
          href="https://github.com/diondiondion/woertchen/"
          className="underline font-bold"
        >
          auf Github
        </a>
        .
      </p>
    </BaseModal>
  )
}
